:root {
    --primary-color: #0068ff;
    --secondary-color: #273339;
    --primary-font-color: #1a1a1a;
    --logo-color: #0F394C;
    --bg-light-color: rgb(240, 244, 255);
    --font-light-color: rgb(97, 107, 112);
    --font-dark-color: rgb(39, 51, 57);
    --box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    --primary-border-color: 3px solid #0F394C;
    --footer-bg: #f1f1f1;
    --cookie-color: rgb(88, 209, 189);
  }
  
  
  

.mg2-dash{
    display: flex;
    padding: 0px;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
}
.topbar2{
    padding: 20px;
    background-color:var(--secondary-color);
}
.sidepage2{
    display: flex;
    padding: 20px;
    height: 100vh;
    overflow: hidden;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color:var(--secondary-color);
    width: 100%;
  
}
.sidebar2{
    width: 15%;
    padding: 20px;
    box-shadow:  0px -4px 40px 0px rgba(0, 0, 0, 0.06);
    background-color: white;
    border-radius: 20px 0px 0px 0px;
}
.mainpage2{
    width: 85%;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 0px 20px 0px 0px;
    padding-right: 0px;
    overflow: hidden;
}

.sidecon2{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}
.sidecon2 a{
    text-decoration: none;
    margin-top: 10px;
    color: var(--primary-font-color);
    font-weight: 500;
}

.sidecon2 svg {
    font-size: 22px;
    float: left;
    margin-right: 6px;
}

.reports-main{
    display: flex;
    flex-direction: row;
}
.reports2{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
    overflow: hidden;
    height: 20%;
}
.reports-box {
    background-color: white;
    padding: 20px;
    padding-top:20px ;
    padding-bottom:10px ;
    margin: 6px;
    border-radius: 20px;
    width: 25%;
    cursor: pointer;
    display: flex;
    height: auto;
    overflow: hidden;
    flex-direction: column;
}
.reports-box img{
    width: 30px;
    margin-bottom: 20px;
}
.reports-box h4{
     font-weight: 800;
     font-size: 22px;
}
.reports-box p{
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    opacity: 0.7;
}
.events2{
    width: 30%;
    padding:10px;
    display: flex;
    background-color: white;
    flex-direction: column;
    margin-top: -20px;
    height: 100vh;
}
.events2 .reports-box {
    width: 100%;
}
.reportsmanagement{
    display: flex;
    width: 70%;
    flex-direction: column;
}
.performance2{
    display: flex;
    flex-direction: row;
    height: 40%;
}
.performance2 .reports-box{
    width: 50%;
}
.performance2 .reports-box h4{
    font-size: 16px;
    font-weight: 700;
}

.events-list{
    display: flex;
    flex-direction: row;
    height: 60px;
    overflow: hidden;
    margin-top: 10px;
    height: 40%;
}

.today{
    margin-top: 40px;
    font-weight: 700;
}
.events-list .eventtype{
    width: 70%;
    border-left: solid #0068ff 2px ;
    padding: 10px;
}
.event-time{
    font-size: 12px;
    width: 20%;
    padding-top: 30px;
    margin-right: 10px;
    font-weight: 700;
   
}
.eventimage img{
    width: 30px;
}
.addnewevent {
    width: 100%;
    display: flex;
 flex-direction: row;
 justify-self: flex-end;
 margin-top: 20px;
}
.addnewevent button{
    justify-content: end;
    padding: 10px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px!important;
    border: 1px #0068ff solid;
    background-color: transparent;
    font-weight: 600;
    color: #0068ff;
}
.reports-box.one {
    width: 30%;
}
.reports-box.two {
    width: 70%;
}
.earnings2.performance2 {
    height: 30%!important;
}

.earnings2 .reports-box.one {
    width: 50%;
}
.earnings2 .reports-box.two {
    width: 50%;
}
.reports-main .msg-page-wrapper.container {
    margin-top: -8px;
    height: auto;
    overflow: hidden;
    padding-right: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-left: -8px;
    background: white;
}
.chat-info h5 img {
    height: 30px;
    border-radius: 50%;
    margin: 0px 10px;
    color: #000;
    width: 30px;
    object-fit: cover;
}

.userdetail2 {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}
.userdetail2 img{
    width: 40px;
}
.userdetail2 p{
   font-size: 16px;
   font-weight: 700;
   margin-left: 10px;
   margin-top: 5px;
   cursor: pointer;
   color: red;
}
.userdetail2 span{
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
    line-height: 0px;
 }
 .reports-main.faculty {
    background: white;
    width: 70%;
    margin-right: 10px;
    margin-top: -5px;
}
.reports-main.faculty .container{
    margin-top: 0px;
}

.reports-main.faculty  .peoples {
    width: 100%;
    float: left;
    padding: 10px;
    background-color: #FAFAF8;
    border-radius: 20px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
   overflow: hidden;
}
.peoples table{
    margin-bottom: -10px;
}

.reports-main.faculty  .peoples table tr {
    margin: 0px;
    float: left;
    width: 100%;
    background-color: white;
 
}
thead tr th, thead tr {
    background-color: #0F394C!important;
    color:white;
}
.topbarmenu2{
    display: flex;
    flex-direction: row;
    margin-bottom: -20px;
}

.topbarmenu2 #logo2{
    color: white;
    text-decoration: none;
    font-weight: 900;
    font-size: 30px;
}
.sidemenu {
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
    padding-left: 0px;
}

.hackathons2 .mg-job-container{
 margin-top: 0px;
 width: 70%;
 margin-right: 10px;
}

.hackathons2 .hackathonDiv{
    width: 100%;
}
