.custom-background {
margin: 5% 10%;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Card Styles */
  .custom-card {
    height: 231px;
    margin: 23px;
    margin-top: 5%;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(66, 66, 66, 0.1);
    padding: 56px 36px;
    display: flex;
    justify-content: space-between;
  }
  
  .custom-header {
    display: flex;
    align-items: center;
  }
  
  .custom-logo-img {
    height: 96px;
    width: 96px;
    border-radius: 24px;
  }
  
  .custom-info {
    margin-left: 32px;
  }
  
  .custom-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .custom-location {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  
  .custom-map-icon {
    width: 20px;
    height: 20px;
  }
  
  .custom-location-text {
    font-size: 0.875rem;
    color: #888;
    margin: 0px;
    padding-left: 10px;
  }
  
  .custom-divider {
    height: 1px;
    border: 0;
    background-color: #f0f0f0;
    margin-top: 36px;
  }
  
  .custom-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px 10px;
  }
  
  .custom-button {
    padding: 10px 25px;
    justify-content: center;
    border-radius: 10px !important;
    margin: 5px;
  }
  
  .custom-button-admission {
    background-color: #0068ff;
    color: #fff;
  }
  
  /* Updates Styles */
  .custom-updates {
    height: 886px;
    margin: 23px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(66, 66, 66, 0.1);
    padding: 22px 42px;
    display: block !important;
  }
  
  .custom-updates-header {
    font-size: 1rem;
    font-weight: bold;
    color: #666;
  }
  
  .custom-update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
  }
  
  .custom-update-image {
    height: 30%;
    width: 40%;
    margin: auto;
    background-color: #d9d9d9;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(66, 66, 66, 0.1);
  }
  
  .custom-update-text {
    margin-left: 35px;
  }
  
  .custom-update-heading {
    font-size: 1.375rem;
  }
  
  .custom-update-description {
    font-size: 0.875rem;
    margin-top: 12px;
  }
  
  /* Nearby Organizations Styles */
  .custom-organizations {
    width: 351px;
    height: 726px;
    margin: 23px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(66, 66, 66, 0.1);
    padding: 31px 31px;
  }
  
  .custom-organizations-header {
    font-size: 0.875rem;
    font-weight: 400;
  }
  
  .custom-organization {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 14px;
  }
  
  .custom-organization-icon {
    background-color: #f9f9f9;
    width: 52px;
    height: 52px;
    border-radius: 10px;
    margin-right: 7px;
  }
  
  .custom-organization-info {
    display: flex;
    flex-direction: column;
  }
  
  .custom-organization-item {
    width: 100%;
    height: 24px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 4px;
  }

  .body-container{
    overflow: hidden;
    display: flex;
    flex-direction: column;

  }