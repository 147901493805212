
@import url('https://fonts.googleapis.com/css2?family=Joti+One&family=Montserrat:ital,wght@1,200&family=Space+Grotesk:wght@300..700&display=swap');
 .pub *{
    font-family: "Space Grotesk", sans-serif;
    margin: 0px;
    padding: 0px;
    margin-left: -10px;
    margin-right: -10px;
}

.mg-banner{
    background-image: url("../media/backg.jpg");
    background-repeat: no-repeat;
    margin-top: -50px;
    margin-right: -20px;
    object-fit: cover;
    font-family: "Space Grotesk", sans-serif;
}
.mg-portfolio {
    padding: 20px;
    overflow-x: hidden;
    max-width: 600px;
    margin: 0 auto; 
    font-family: "Space Grotesk", sans-serif;
 
}

.profile-image {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-top: 100px;
    font-family: "Space Grotesk", sans-serif;
}

.profile-image img {
    width: 100px;
    height: 100px;
    font-family: "Space Grotesk", sans-serif;
    border-radius: 100px;
    border: solid white 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 16px -1px;
    object-fit: cover;
}
.profile-image h2 {
    margin-top: 40px;
    font-size: 50px;
    font-weight: 800;
    font-family: "Space Grotesk", sans-serif;
    color: #111111;
}
.profile-image p{
    font-size: 20px; 
    font-family: "Space Grotesk", sans-serif;
    color: #666666;
}
.mg-social-media{
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    margin-top: 30px;
    margin-left: 20%;
    font-family: "Space Grotesk", sans-serif;
}

.socialIcon a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    background: rgb(243, 247, 249);
    width: 60px;
    height:60px;
    font-family: "Space Grotesk", sans-serif;
    text-decoration: none;
    float: left;
    border-radius: 50px;
}
.ed-de{
    display: flex;
    flex-direction: column;
    font-family: "Space Grotesk", sans-serif;
    width:100%;
}
.education{
    font-family: "Space Grotesk", sans-serif;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
   
   


}
.education h4{
    color:#111;
    font-size: 14px;
    padding: 4px;
    font-weight: 900;
    text-align: left;
    font-family: "Space Grotesk", sans-serif;
    padding-left: 5px;
}
.education-details{
    display: flex;
    background-color:rgb(243, 247, 249);
    flex-direction: row;
    padding: 20px;
    margin-top: -10px;
    border-radius: 20px;
    height: 65px;
    font-family: "Space Grotesk", sans-serif;
}
.ed-de h1{
    font-size: 18px;
    color: #111;
    font-family: "Space Grotesk", sans-serif;
}
.ed-de span{
    font-size: 15px;
    color: #111;
    font-family: "Space Grotesk", sans-serif;
}
.affilated{
    text-align: center;
    width: 25%;
    font-family: "Space Grotesk", sans-serif;
    display: flex;
    justify-content: center;
}
.affilated p{
    background-color: rgb(88, 106, 245);
    width: 100px;
    height: 25px;
    border-radius: 8px; font-family: "Space Grotesk", sans-serif;
    color: #fff;
}
.skills {
    display: flex; font-family: "Space Grotesk", sans-serif;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: -5px;
}
.skills-dev{
    background-color: rgb(243, 247, 249);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    cursor: pointer; font-family: "Space Grotesk", sans-serif;
}

.cert-dev{
    background-color: rgb(243, 247, 249);
    padding: 5px;
    padding-left: 10px;
    width: 200px;
    padding-right: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer; font-family: "Space Grotesk", sans-serif;
}
.cert-dev a{
    text-decoration: none;
}

.connect {
    display: flex;
    color: #fff;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    background: rgba(243, 247, 249,0.2); font-family: "Space Grotesk", sans-serif;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 150px;
}
.connect p{
    margin-top: 5px; font-family: "Space Grotesk", sans-serif;
}
.connect a{
    background-color: rgb(88, 106, 245);
    padding: 10px;
    width: 30%;
    margin-left: 35%; font-family: "Space Grotesk", sans-serif;
    margin-top: 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
}
.copyright a{
    font-size: 12px;
    color: #000;
    margin-bottom: 50px;
    text-decoration: none; font-family: "Space Grotesk", sans-serif;
}
.template {
    position: fixed;
    bottom: 0;
    margin: 40px;
    width: 200px;
    right: 0; font-family: "Space Grotesk", sans-serif;
    text-align: center;
    background-color: white;
    padding: 10px;
    height: 43px;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.05);
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
   
 
}
.template a{
    text-decoration: none; font-family: "Space Grotesk", sans-serif;
    color: #111;
    font-size: 17px;
    font-weight: 900;
  
    
}
.copyright {
    text-align: center;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif !important;
    float: left;
    width: 100%; font-family: "Space Grotesk", sans-serif;
    margin-bottom: 100px;
}
#qrcodep {
    margin-left: 37%;
    background: rgb(243, 247, 249);
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    margin-bottom: 20px; font-family: "Space Grotesk", sans-serif;
}
@media only screen and (max-width: 1200px) {
   
    #qrcodep {
        margin-left: 15%;
        background: rgb(243, 247, 249);
        padding: 10px;
        border-radius: 10px;
        width: 70% !important;
        margin-bottom: 20px; font-family: "Space Grotesk", sans-serif;
        margin-top: 22px;
        height: 100% !important;
    }
    .mg-portfolio {
        padding: 20px;
        overflow-x: hidden;
        max-width: 600px;
        margin: 0 auto;
        padding: 50px; font-family: "Space Grotesk", sans-serif;
        padding-left: 30px;
    }
    .profile-image {
        display: flex;
        flex-direction: column;
        align-items: center; font-family: "Space Grotesk", sans-serif;
        margin-top: 60px;
    }
    .profile-image h2 {
        margin-top: 20px;
        font-size: 30px; font-family: "Space Grotesk", sans-serif;
        font-weight: 800;
        color: #111111;
    }
    .profile-image p {
        font-size: 18px;
        color: #666666; font-family: "Space Grotesk", sans-serif;
    }
    .mg-social-media {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 30px; font-family: "Space Grotesk", sans-serif;
        margin-left: 0%;
    }
    .ed-de {
        display: flex;
        flex-direction: column;
        width: 100%; font-family: "Space Grotesk", sans-serif;
        margin-left: 10px;
    }
    .ed-de h1 {
        font-size: 14px;
        color: #111; font-family: "Space Grotesk", sans-serif;
    }
    .affilated p {
        background-color: rgb(88, 106, 245);
        width: 100px;
        height: 25px;
        border-radius: 8px;
        color: #fff; font-family: "Space Grotesk", sans-serif;
        display: none;
    }
    .ed-de span {
        font-size: 15px; font-family: "Space Grotesk", sans-serif;
        color: #111;
        margin-left: 5px;
    }

    .skills {
        display: flex;
        flex-wrap: wrap;  font-family: "Space Grotesk", sans-serif;
        justify-content: flex-start; 
        margin-top: -5px;
    }
    
    .skills-dev {
        background-color: rgb(243, 247, 249);
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px; font-family: "Space Grotesk", sans-serif;
        border-radius: 8px;
        cursor: pointer;
        margin: 1px;
        width: calc(20% - 20px);
        text-align: center;
        width: 49%;
    }
.connect h3 {
    font-size: 26px;
    font-family: 'mg-semibold';
    text-align: center; font-family: "Space Grotesk", sans-serif;
}
.connect p {
    margin-top: 5px;
    font-size: 16px;
}
.connect a {
    background-color: rgb(88, 106, 245);
    padding: 10px;
    width: 60%; font-family: "Space Grotesk", sans-serif;
    margin-left: 20%;
    margin-top: 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    text-align: center;
}
.connect { font-family: "Space Grotesk", sans-serif;
    display: flex;
    color: #fff;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    background: rgba(243, 247, 249, 0.2);
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 20px;
}
}
img#verification {
    box-shadow: none;
    width: 30px;
    height: 30px;
}

.education h4 {
    color: #111;
    font-size: 14px;
    padding: 4px;
    font-weight: 900;
    text-align: left;
    font-family: "Space Grotesk", sans-serif;
    padding-left: 5px;
    margin-top: 26px;
    margin-bottom: 10px;
}
.mg-row{
   display: flex;
   flex-direction: row;
 
}
.mg-left{
    width: 50%;
    justify-content: left;
    text-align: left;
    padding: 0px;
    float: left;
    display: flex;
    flex-direction: column;

}
.mg-left h1{
    width: 100%;
    text-align: left;
    font-weight: 900!important;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    margin-left: -20px;
}
.mg-left p{
    margin-left: -20px;
    font-size: 20px;
}
.mg-btn {
    width: 100%;
    float: left;
    margin-left: -20px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.mg-btn button:nth-child(2){
    margin-left: 10px;
    background-color: transparent;
    color: white;
    border: solid white 1px;
}
.mg-btn button{
background-color:#fff;
color: black;
font-weight: 500;
border: solid #fff 1px;
}
#offer{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.mg-boxes.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -40px;
    margin-top: -40px;
    float: left;
}

.box{
    background-color: white;
    margin: 10px;
    width: 100%;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.box img{
    width: 40px;

}

.box h3{
    font-size: 22px;
    margin-top: 20px;
    font-weight: 700;
    color: #1a2c47;
}
.box p{
    margin-top:5px;
    font-size: 12px;
    font-weight: 600;
    opacity: .5;

} 

.experts.container {
    margin-top: 77px;
    width: 100%;
    float: left;
}
.expert-box{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-left: -10px;
}

.expert {
    padding: 40px;
    border:0.1rem solid #d6dee5;
    border-radius: 20px;
    margin: 10px;
    min-width: 15%;
    width: 30%;
}


.expert h2{
    font-size: 40px;
    font-weight: 800;
    width: 100%;
    text-align: left;
}
.experts h1{
    color: #000;
    font-size: 40px;
    font-weight: 400;
    position: relative;
}

.experts h1::after {
    content: '';
    float: left;
    position: absolute;
    width:15%;
    margin-top: 50px;
    margin-left: -220px;
    height: 0.4rem;
    background: radial-gradient(46.9% 46.9% at 50% 50%, #0068ff 61.46%, rgba(255, 255, 255, 0) 100%);
}

.doubts{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 100px;
    float: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    padding: 40px;
    border-radius: 2.4rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: -webkit-gradient(linear, left top, right top, from(#0068ff), color-stop(#0068ff), to(#0245b9));
    background: linear-gradient(to right, #0068ff, #0068ff, #0068ff);
}
.doubts.container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 55px;
    justify-content:left;
    width: 96%;
    height: 260px;
}
.doubts.container img {
    width: 306px;
    opacity: 0.3;
    margin-top: -100px;
    margin-left: 200px;
}
.doubts.container {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content:space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-bottom: 50px;
}
.doubts.container h1{
    color: white;
    font-weight: 800;
}
.doubts.container button{
    padding: 10px;
    width: 240px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px!important;

}
.founder.join {
    margin-top: 100px;
}
.founder.container {
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    justify-content: space-between;
}
.founder.container h2{
    color: white;
    text-align: left;
    font-weight: 400;
    font-size: 45px;
    width: 140%;
}
#bold{
    font-weight: 800!important;
    text-transform: capitalize;
}
.founder.container img{
    width: 60%;
    border-radius: 10px;

}

.founder.container p{
    color: wheat;
    text-align: left;
    font-weight: 700;
    margin-top: 30px;
    font-size: 26px;
}
#taglineType{
    color: white;
    font-size: 12px;
    float: left;
    margin-top: -10px;
}

#quote{
    width: 100%;
    text-align: left;
    color: white;
    float: left;
    font-family: "Space Grotesk", sans-serif;
    width: 80%;
    margin-top: 20px;
}
.founder.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 73px;
}

.mg-right img {
    width: 70%;
    margin-top: -170px;
    margin-left: 30%;
}

.mg-right {
    height: 559px;
    overflow: hidden;
    margin-bottom: -139px;
}
#logo {
    width: 69px !important;
    height: auto;
}